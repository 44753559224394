import {
  useCallback,
  useEffect,
  useState,
  useReducer,
  useMemo,
  useContext,
} from 'react'
import { useParams } from 'react-router-dom'
import { FaFilter as FilterSvg } from 'react-icons/fa'
import { Divider, Flex, HStack, VStack } from '@chakra-ui/react'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Autocomplete from 'components/Autocomplete'
import nloop from './nloop'
import Niveis from './Niveis'
import { handleGetOptions } from './actions'
import { niveisIniState, niveisReducer } from './reducer'
import api from 'services/api'
import IFilters from 'pages/PriceBaseTable/types/IFilters'
import { UserContext } from 'state/user-context'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
}

export interface LevelsSelectedState {
  '1': IOption[]
  '2': IOption[]
  '3': IOption[]
  '4': IOption[]
  '5': IOption[]
}

const FilterButton: React.FC<IProps> = ({ filters, setFilters }) => {
  const { user } = useContext(UserContext)
  const { id } = useParams()
  const [isFiltering, setIsFiltering] = useState(false)
  const [levels, setLevels] = useState<any>({
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
  })
  const [levelsSelected, setLevelsSelected] = useState<any>({
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
  })
  const [niveis, dispatch] = useReducer(niveisReducer, niveisIniState)
  const [clusterIdOpt, setClusterIdOpt] = useState<IOption[]>([])
  const [clusterIdOptSelected, setClusterIdOptSelected] = useState<IOption[]>(
    []
  )
  const [clientOpt, setClientOpt] = useState<IOption[]>([])
  const [clientSelected, setClientSelected] = useState([])
  const [channelOpt, setChannelOpt] = useState<IOption[]>([])
  const [skuOpt, setSkuOpt] = useState<IOption[]>([])
  const [skuSelected, setSkuSelected] = useState<IOption[]>([])
  const [channelOptSelected, setChannelOptSelected] = useState<IOption[]>([])
  const [captainOpt, setCaptainOpt] = useState<IOption[]>([])
  const [captainOptSelected, setCaptainOptSelected] = useState<IOption[]>([])
  const [classificationOpt, setClassificationOpt] = useState<IOption[]>([])
  const [classificationOptSelected, setClassificationOptSelected] = useState<
    IOption[]
  >([])

  const request = useMemo(() => {
    const newRequest: { [n: string]: string[] } = {}

    nloop.forEach((n) => {
      newRequest[`nivel0${n}`] = niveis[n].map((o) => o.value)
    })

    return newRequest
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [niveis, filters])

  const getOptions = useCallback(() => {
    if (!id) return

    handleGetOptions(id, request, (data: any) => {
      if (user?.clusters?.includes(0)) {
        setClusterIdOpt(data.cluster || [])
      } else {
        const clusterFiltered = data.cluster?.filter((item: any) =>
          user?.clusters?.includes(item.value)
        )
        setClusterIdOpt(clusterFiltered)
      }
      setClientOpt(
        data.client?.map((v: any) => ({
          label: v?.client_name.trim(),
          value: v?.client_code,
        })) || []
      )
      setClassificationOpt(data.classificacao_item || [])
      setSkuOpt(data.sku || [])
      setCaptainOpt(data.captains || [])
      setChannelOpt(
        data.channel?.map((v: any) => ({
          label: v?.channel_name,
          value: v?.channel_code,
        })) || []
      )
      dispatch({ type: 'SETOPTS', payload: data.niveis })
    })
  }, [id, request, user])

  useEffect(() => {
    getOptions()
  }, [getOptions])

  useEffect(() => {
    const getLevels = async () => {
      api.post('materials/filter/level-1').then((res: any) => {
        setLevels({
          [1]: res.data.data.result.map(
            (item: { level_name: string; level_code: string }) => ({
              label: item.level_name,
              value: item.level_code,
            })
          ),
        })
      })
    }
    getLevels()
  }, [])

  const setNivel = useCallback((data: { [n: string]: IOption[] }) => {
    dispatch({ type: 'SETNIVEL', payload: data })
  }, [])

  const setDefaultData = useCallback(() => {
    nloop.forEach((n) => {
      setNivel({ [n]: filters[`nivel0${n}`] || [] })
    })
    setLevelsSelected({
      '1': filters.level1_code,
      '2': filters.level2_code,
      '3': filters.level3_code,
      '4': filters.level4_code,
      '5': filters.level5_code,
    })
  }, [filters, setNivel])

  useEffect(() => {
    setDefaultData()
  }, [setDefaultData])

  const handleOpenFilter = () => {
    setIsFiltering(true)
  }

  const onFilter = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: clusterIdOptSelected,
      classificacao_item: classificationOptSelected,
      captain_code: captainOptSelected,
      channel_code: channelOptSelected,
      sku: skuSelected,
      client_code: clientSelected,
    }
    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = levelsSelected[n]
    })

    setIsFiltering(false)
    setFilters(newFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channelOptSelected,
    captainOptSelected,
    classificationOptSelected,
    clusterIdOptSelected,
    niveis,
    setFilters,
    clientSelected,
    levelsSelected,
    skuSelected
  ])

  const onClear = useCallback(() => {
    const newFilters: { [n: string]: IOption[] } = {
      cluster_id: [],
      classificacao_item: [],
      captain_code: [],
      channel_code: [],
      sku: [],
      client_code: [],
    }
    setCaptainOptSelected([])
    setChannelOptSelected([])
    setClassificationOptSelected([])
    setClusterIdOptSelected([])
    setClientSelected([])
    setSkuSelected([])
    nloop.forEach((n) => {
      newFilters[`level${n}_code`] = []
    })
    setIsFiltering(false)
    setFilters(newFilters)
  }, [setFilters])

  const onClose = () => {
    setIsFiltering(false)
  }
  return (
    <>
      <Flex>
        <Button
          padding="0.5rem 1rem"
          onClick={handleOpenFilter}
          containerStyle={{
            backgroundColor: isFiltering ? '#38A169' : '#003b74',
          }}
        >
          <FilterSvg />
        </Button>
      </Flex>
      <Modal
        w="50rem"
        isOpen={isFiltering}
        title="Filtros"
        onClose={onClose}
        body={
          <>
              <HStack align="flex-start" spacing="1rem">
            <VStack w="100%" align="flex-start">
              <Niveis
                niveis={niveis}
                setNivel={setNivel}
                levels={levels}
                setLevels={setLevels}
                levelsSelected={levelsSelected}
                setLevelsSelected={setLevelsSelected}
              />
            </VStack>
            <VStack w="100%" align="flex-start">
              <Autocomplete
                isMulti
                label="Cluster"
                value={clusterIdOptSelected}
                options={clusterIdOpt}
                setValue={setClusterIdOptSelected}
              />
              <Autocomplete
                isMulti
                label="Canais"
                value={channelOptSelected}
                options={channelOpt}
                setValue={setChannelOptSelected}
              />
              <Autocomplete
                isMulti
                label="Capitão"
                value={captainOptSelected}
                options={captainOpt}
                setValue={setCaptainOptSelected}
              />
               <Autocomplete
                isMulti
                label="SKU"
                value={skuSelected}
                options={skuOpt}
                setValue={setSkuSelected}
              />
              <Autocomplete
                isMulti
                label="Classificação Produto"
                value={classificationOptSelected}
                options={classificationOpt}
                setValue={setClassificationOptSelected}
              />             
              {/* <ClientAutocomplete
                isMulti
                label="Cliente"
                value={filters.client}
                options={clientOpt}
                setValue={setClientSelected}
                // setValue={(newVal: IOption[]) => {
                //   setFilters((current) => {
                //     return { ...current, client: newVal }
                //   })
                // }}
              /> */}
            </VStack>            
          </HStack>
          <HStack marginTop={3}>
          <Autocomplete
            isMulti
            label="Cliente"
            value={clientSelected}
            options={clientOpt}
            setValue={setClientSelected}
          />
          </HStack>
          </>
        }
        footer={
          <VStack w="100%" align="flex-start">
            <Divider />
            <Flex w="100%" pt="0.5rem" justifyContent="space-between">
              <Button onClick={onClear}>Limpar</Button>
              <Button onClick={onFilter}>Filtrar</Button>
            </Flex>
          </VStack>
        }
      />
    </>
  )
}

export default FilterButton
