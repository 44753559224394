const styles = (minWidth: any) => ({
  headerStyle: { backgroundColor: '#d0e1fd', color: '#003b74' },
  minwidth: `${minWidth}px`,
  bodyStyle: { padding: '10' },
})

export const colsSimple = [
  {
    field: 'cluster_name',
    header: 'Cluster',
    style: styles(100),
    frozen: true,
  },
  { field: 'channel_name', frozen: true, header: 'Canal', style: styles(100) },
  {
    field: 'captain_code',
    header: 'Cód. Capitão',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'sku',
    header: 'SKU',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'descricao_produto',
    header: 'Descrição',
    style: styles(350),
    frozen: true,
  },
  {
    field: 'classificacao_item',
    header: 'Classificação Item',
    style: styles(120),
  },
  {
    field: 'preco_bruto_atual',
    header: 'Preço bruto realizado atual (kg)',
    style: styles(120),
  },
  {
    field: 'mrg_bruta_atual',
    header: 'Mrg. bruta atual sobre preço bruto realizado atual',
    style: styles(160),
  },
  {
    field: 'preco_bruto_sugestao',
    header: 'Preço bruto sugestão (kg)',
    style: styles(140),
  },
  {
    field: 'variacao_preco',
    header: 'Variação de Preço',
    style: styles(120),
  },
  {
    field: 'lucro_bruto_sobre_receita_bruta',
    header: 'Lucro bruto sobre receita bruta',
    style: styles(160),
  },
  {
    field: 'margem_bruta_sobre_receita_bruta',
    header: 'Mrg. bruta sobre receita bruta',
    style: styles(160),
  },
  { field: 'mkp_da_cadeia', header: 'Markup da cadeia', style: styles(120) },
  { field: 'novo_ppc_c_mkp', header: 'Novo ppc c/ mkp', style: styles(120) },
  {
    field: 'descolamento_meta',
    header: 'Descolamento meta',
    style: styles(120),
  },
  {
    field: 'preco_concorrencia_consumidor',
    header: 'Preço concorrência ao consumidor P1 (kg)',
    style: styles(160),
  },
  {
    field: 'descolamento_real',
    header: 'Descolamento da concorrência P1',
    style: styles(140),
  },  
  {
    field: 'preco_concorrencia_consumidor_geral',
    header: 'Preço concorrência ao consumidor geral (kg)',
    style: styles(160),
  },  
  {
    field: 'descolamento_concorrencia_geral_2',
    header: 'Descolamento da concorrência geral',
    style: styles(140),
  },
  {
    field: 'custo_medio_produto',
    header: 'Custo médio produto',
    style: styles(120),
  },
  { field: 'custo_frete', header: 'Custos + Frete', style: styles(120) },
]

export const colsDetailed = [
  {
    field: 'cluster_name',
    header: 'Cluster',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'channel_name',
    header: 'Canal',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'captain_code',
    header: 'Cód. Capitão',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'sku',
    header: 'SKU',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'descricao_produto',
    header: 'Descrição',
    style: styles(350),
    frozen: true,
  },
  // { field: 'level1_name', header: 'Nível 1', style: styles(120) },
  // { field: 'level2_name', header: 'Nível 2', style: styles(120) },
  // { field: 'level3_name', header: 'Nível 3', style: styles(120) },
  // { field: 'level4_name', header: 'Nível 4', style: styles(120) },
  // { field: 'level5_name', header: 'Nível 5', style: styles(120) },
  {
    field: 'classificacao_item',
    header: 'Classificação Item',
    style: styles(120),
  },
  {
    field: 'tributacao',
    header: 'Tributação',
    style: styles(120),
  },
  { field: 'peso_embalagem', header: 'Peso por embalagem', style: styles(120) },  
  {
    field: 'preco_alvo_consumidor',
    header: 'Preço-alvo ao consumidor (kg)',
    style: styles(160),
  },
  {
    field: 'relatividade_sku',
    header: 'Relatividade sku',
    style: styles(120),
  },
  {
    field: 'preco_relativo_sku',
    header: 'Preço-alvo relativo do sku ao consumidor (kg)',
    style: styles(160),
  },
  { field: 'mkp_da_cadeia_2', header: 'Markup da cadeia', style: styles(120) },
  // { field: 'novo_ppc_c_mkp', header: 'Novo ppc c/ mkp', style: styles(120) },
  {
    field: 'preco_alvo_s_mkp',
    header: 'Preço-alvo do consumidor s/ markup (kg)',
    style: styles(170),
  },  
  {
    field: 'mrg_bruta_meta',
    header: 'Mrg bruta meta (calculada sobre receita bruta)',
    style: styles(170),
  },
  { field: 'pis_cofins_origem', header: 'Pis/cofins', style: styles(120) },
  { field: 'icms_origem', header: 'Icms origem', style: styles(120) },
  { field: 'ipi', header: 'Ipi', style: styles(120) },
  { field: 'mva', header: 'Mva', style: styles(120) },
  { field: 'icms_st', header: 'Icms st', style: styles(120) },
  {
    field: 'preco_bruto_atual',
    header: 'Preço bruto realizado atual (kg)',
    style: styles(120),
  },
  {
    field: 'mrg_bruta_atual',
    header: 'Mrg. bruta atual sobre preço bruto realizado atual',
    style: styles(160),
  },
  {
    field: 'preco_embalagem_atual',
    header: 'Preço bruto embalagem',
    style: styles(140),
  },
  {
    field: 'lucro_bruto_atual',
    header: 'Lucro bruto sobre preço bruto realizado atual',
    style: styles(160),
  },
  {
    field: 'preco_liquido_sugestao',
    header: 'Preço líquido sugestão (kg)',
    style: styles(140),
  },
  {
    field: 'margem_bruta_sobre_receita_liquida',
    header: 'Mrg. bruta sobre receita líquida',
    style: styles(150),
  },
  {
    field: 'lucro_bruto_tabela_sugerida',
    header: 'Lucro bruto tabela sugerida',
    style: styles(140),
  },

  { field: 'relativ_canal', header: 'Relatividade canal', style: styles(120) },
  {
    field: 'preco_bruto_sugestao',
    header: 'Preço bruto sugestão (kg)',
    style: styles(140),
  },
  {
    field: 'preco_embalagem_sugestao',
    header: 'Preço embalagem sugestão',
    style: styles(150),
  },
  {
    field: 'base_cal_pis_cofins',
    header: 'Base calc. pis/cofins',
    style: styles(120),
  },
  { field: 'valor_mva', header: 'Valor mva', style: styles(120) },
  { field: 'valor_icms', header: 'Valor icms st', style: styles(120) },
  {
    field: 'preco_bruto_cst',
    header: 'Preço bruto sugerido c/ st (kg)',
    style: styles(160),
  },

  {
    field: 'lucro_bruto_sobre_receita_bruta',
    header: 'Lucro bruto sobre receita bruta',
    style: styles(160),
  },
  {
    field: 'margem_bruta_sobre_receita_bruta',
    header: 'Mrg. bruta sobre receita bruta',
    style: styles(160),
  },  
  { field: 'mkp_da_cadeia_2', header: 'Markup da cadeia', style: styles(120) },
  { field: 'novo_ppc_c_mkp', header: 'Novo ppc c/ mkp', style: styles(120) },
  {
    field: 'descolamento_meta',
    header: 'Descolamento meta',
    style: styles(120),
  },
  {
    field: 'preco_concorrencia_consumidor',
    header: 'Preço concorrência ao consumidor P1 (kg)',
    style: styles(160),
  },  
  {
    field: 'descolamento_real',
    header: 'Descolamento da concorrência P1',
    style: styles(140),
  },
  {
    field: 'preco_concorrencia_consumidor_geral',
    header: 'Preço concorrência ao consumidor geral (kg)',
    style: styles(160),
  },  
  {
    field: 'descolamento_concorrencia_geral_2',
    header: 'Descolamento da concorrência geral',
    style: styles(140),
  }, 
  { field: 'frete', header: 'Frete', style: styles(120) },
  {
    field: 'custo_medio_produto',
    header: 'Custo médio produto',
    style: styles(120),
  },
  { field: 'custo_frete', header: 'Custos + Frete', style: styles(120) },
  { field: 'level1_name', header: 'Nível 1', style: styles(120) },
  { field: 'level2_name', header: 'Nível 2', style: styles(120) },
  { field: 'level3_name', header: 'Nível 3', style: styles(120) },
  { field: 'level4_name', header: 'Nível 4', style: styles(120) },
  { field: 'level5_name', header: 'Nível 5', style: styles(120) },
]

export const colsDetailedChildren = [
  { field: 'cluster_name', header: 'Cluster', style: styles(100) },
  { field: 'channel_name', header: 'Canal', style: styles(100) },
  { field: 'captain_code', header: 'Cód. Capitão', style: styles(100) },
  { field: 'sku', header: 'SKU', style: styles(100) },
  { field: 'descricao_produto', header: 'Descrição' },

  { field: 'level1_name', header: 'Nível 1' },
  { field: 'level2_name', header: 'Nível 2' },
  { field: 'level3_name', header: 'Nível 3' },
  { field: 'level4_name', header: 'Nível 4' },
  { field: 'level5_name', header: 'Nível 5' },
  { field: 'classificacao_item', header: 'Classificação Item' },
  {
    field: 'ppc_base',
    header: 'PPC Base',
  },
  { field: 'indice_meta', header: 'Indice' },
  { field: 'ppc_index', header: 'PPC Indice' },
  { field: 'relatividade_sku', header: 'Relatividade SKU' },
  { field: 'ppc_relativo', header: 'PPC Relativo' },
  { field: 'mrg_mercado', header: 'Mrg Mercado Pond.' },
  { field: 'ppc_s_mrg', header: 'PPC Sem Margem' },
  {
    field: 'tipo_imposto',
    header: 'Tipo Tributação',
  },
  { field: 'pis_cofins_destino', header: 'PIS+COFINS Destino' },
  { field: 'icms_destino', header: 'ICMS Destino' },
  { field: 'mva', header: 'MVA' },
  { field: 'icms_st', header: 'ICMS ST' },
  { field: 'mrg_ppv_objetiv', header: 'Mrg PPV Objetiva' },
  { field: 'relat_canal', header: 'Relat. Canal' },
  { field: 'custo_varejo', header: 'Custo Varejo' },
  {
    field: 'mrg_min',
    header: 'Margem Mín',
  },
  { field: 'ppv_liq_min', header: 'PPV Líq. Mín' },
  { field: 'mrg_max', header: 'Margem Máx' },
  { field: 'ppv_liq_max', header: 'PPV Líq. Máx' },
  { field: 'ppv_liq_atual', header: 'PPV Líq. Atual' },

  { field: 'ppv_novo', header: 'PPV Novo' },
  { field: 'pis_cofins_origem', header: 'PIS/COFINS Origem' },
  { field: 'icms_origem', header: 'ICMS Origem' },
  { field: 'ipi', header: 'IPI' },
  { field: 'ppv_bruto_novo', header: 'PPV Bruto Novo' },
  { field: 'mrg_ppv_novo', header: 'Margem PPV Novo' },
  {
    field: 'novo_ppc_s_mrg',
    header: 'Novo PPC S/ Mrg',
  },
  { field: 'novo_ppc_s_mrg', header: 'Novo PPC S/ Mrg' },
  { field: 'novo_ppc', header: 'Novo PPC' },
  { field: 'desconto', header: 'Desconto' },
  { field: 'novo_ppc_c_desc', header: 'Novo PPC Desconto' },
  { field: 'indice_final', header: 'Índice Final' },
  { field: 'volume_total', header: 'Volume Total' },
  { field: 'custo_medio_produto', header: 'Custo Médio Produto' },
  { field: 'faturamento', header: 'Faturamento Bruto' },
  { field: 'faturamento_liquido', header: 'Faturamento Líquido' },
  { field: 'lucro_liquido', header: 'Lucro Líquido' },
]

export const colsTradingCondition = [
  {
    field: 'cluster_name',
    header: 'Cluster',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'channel_name',
    header: 'Canal',
    style: styles(150),
    frozen: true,
  },
  {
    field: 'client_name',
    header: ' Nome cliente',
    style: styles(300),
    frozen: true,
  },
  {
    field: 'captain_code',
    header: 'Cód. Capitão',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'sku',
    header: 'SKU',
    style: styles(100),
    frozen: true,
  },
  {
    field: 'descricao_produto',
    header: 'Descrição',
    style: styles(350),
    frozen: true,
  },  
  {
    field: 'classificacao_item',
    header: 'Classificação Item',
    style: styles(120),
  },
  {
    field: 'tributacao',
    header: 'Tributação',
    style: styles(120),
  },
  { field: 'peso_embalagem', header: 'Peso por embalagem', style: styles(120) },   
  {
    field: 'preco_alvo_consumidor',
    header: 'Preço-alvo ao consumidor (kg)',
    style: styles(160),
  },
  { field: 'relatividade_sku', header: 'Relatividade sku', style: styles(120) },
  {
    field: 'preco_relativo_sku',
    header: 'Preço-alvo relativo do sku ao consumidor (kg)',
    style: styles(160),
  },
  { field: 'mkp_da_cadeia', header: 'Markup da cadeia', style: styles(120) },
  {
    field: 'preco_alvo_s_mkp',
    header: 'Preço-alvo do consumidor s/ markup (kg)',
    style: styles(170),
  },
  {
    field: 'mrg_bruta_meta',
    header: 'Mrg bruta meta (calculada sobre receita bruta)',
    style: styles(170),
  },
  { field: 'pis_cofins_origem', header: 'Pis/cofins', style: styles(120) },
  { field: 'icms_origem', header: 'Icms origem', style: styles(120) },
  { field: 'ipi', header: 'Ipi', style: styles(120) },
  { field: 'mva', header: 'Mva', style: styles(120) },
  { field: 'icms_st', header: 'Icms st', style: styles(120) },
  {
    field: 'preco_bruto_atual',
    header: 'Preço bruto realizado atual (kg)',
    style: styles(120),
  },
  {
    field: 'mrg_bruta_atual',
    header: 'Mrg. bruta atual sobre preço bruto realizado atual',
    style: styles(160),
  },
  {
    field: 'preco_embalagem_atual',
    header: 'Preço bruto embalagem',
    style: styles(140),
  },
  {
    field: 'lucro_bruto_atual',
    header: 'Lucro bruto sobre preço bruto realizado atual',
    style: styles(160),
  },
  {
    field: 'preco_liquido_sugestao',
    header: 'Preço líquido sugestão (kg)',
    style: styles(140),
  },
  {
    field: 'margem_bruta_sobre_receita_liquida',
    header: 'Mrg. bruta sobre receita líquida',
    style: styles(150),
  },
  {
    field: 'lucro_bruto_tabela_sugerida',
    header: 'Lucro bruto tabela sugerida',
    style: styles(140),
  },

  { field: 'relativ_canal', header: 'Relatividade canal', style: styles(120) },
  {
    field: 'preco_bruto_sugestao',
    header: 'Preço bruto sugestão (kg)',
    style: styles(140),
  },
  {
    field: 'preco_embalagem_sugestao',
    header: 'Preço embalagem sugestão',
    style: styles(150),
  },
  {
    field: 'base_cal_pis_cofins',
    header: 'Base calc. pis/cofins',
    style: styles(120),
  },
  { field: 'valor_mva', header: 'Valor mva', style: styles(120) },
  { field: 'valor_icms', header: 'Valor icms st', style: styles(120) },
  {
    field: 'preco_bruto_cst',
    header: 'Preço bruto sugerido c/ st (kg)',
    style: styles(160),
  },

  {
    field: 'lucro_bruto_sobre_receita_bruta',
    header: 'Lucro bruto sobre receita bruta',
    style: styles(160),
  },
  {
    field: 'margem_bruta_sobre_receita_bruta',
    header: 'Mrg. bruta sobre receita bruta',
    style: styles(160),
  },
  { field: 'mkp_da_cadeia_2', header: 'Markup da cadeia', style: styles(120) },
  { field: 'novo_ppc_c_mkp', header: 'Novo ppc c/ mkp', style: styles(120) },
  {
    field: 'descolamento_meta',
    header: 'Descolamento meta',
    style: styles(120),
  },
  {
    field: 'preco_concorrencia_consumidor',
    header: 'Preço concorrência ao consumidor P1 (kg)',
    style: styles(160),
  },  
  {
    field: 'descolamento_real',
    header: 'Descolamento da concorrência P1',
    style: styles(140),
  },
  {
    field: 'preco_concorrencia_consumidor_geral',
    header: 'Preço concorrência ao consumidor geral (kg)',
    style: styles(160),
  },  
  {
    field: 'descolamento_concorrencia_geral_2',
    header: 'Descolamento da concorrência geral',
    style: styles(140),
  },
  // {
  //   field: 'descolamento_real',
  //   header: 'Descolamento real',
  //   style: styles(120),
  // },
  { field: 'frete', header: 'Frete', style: styles(120) },
  {
    field: 'custo_medio_produto',
    header: 'Custo médio produto',
    style: styles(120),
  },
  { field: 'custo_frete', header: 'Custos + Frete', style: styles(120) },

  { field: 'acoes_comerciais', header: 'Ações comerciais', style: styles(120) },
  { field: 'bonificacoes', header: 'Bonificações', style: styles(120) },
  { field: 'comissoes', header: 'Comissões', style: styles(120) },
  { field: 'descontos_fixos', header: 'Descontos fixo', style: styles(120) },
  {
    field: 'descontos_variaveis',
    header: 'Descontos variáveis',
    style: styles(120),
  },
  {
    field: 'margem_contribuicao',
    header: 'Margem contribuição',
    style: styles(120),
  },
  { field: 'level1_name', header: 'Nível 1', style: styles(120) },
  { field: 'level2_name', header: 'Nível 2', style: styles(120) },
  { field: 'level3_name', header: 'Nível 3', style: styles(120) },
  { field: 'level4_name', header: 'Nível 4', style: styles(120) },
  { field: 'level5_name', header: 'Nível 5', style: styles(120) },
]
