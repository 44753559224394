import React, {
  KeyboardEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react'
import { useToast } from '@chakra-ui/react'
import api from 'services/api'
import { UserContext } from 'state/user-context'
import { InputText } from 'primereact/inputtext'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './styles.css'

interface IProps {
  row: { [key: string]: any }
  updateRow: (newRow: { [key: string]: any }) => void
  field: string
}

export const InputToFilter: React.FC<IProps> = ({ row, updateRow, field }) => {

  const toast = useToast()
  const { userScenery } = useContext(UserContext)
  const formatValue = (value: any) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 1,
    })
      ?.format(parseFloat(String(value)?.replace(',', '.')))

  const [value, setValue] = useState(
    !row[field] || isNaN(row[field])
      ? '0,0'
      : formatValue(row[field])
  )
  const [focusValue, setFocusValue] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const borderColor = useMemo(
    () =>
      `${
        row.preco_bruto_sugestao !== row.ppc_s_mrg ? '#BEE3F8' : '#E2E8F0'
      } !important`,
    [row.ppc_s_mrg, row.preco_bruto_sugestao]
  )

  const onBlur = () => {
    updateRow({
      ...row,
      [field]: value,
    })
  }

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value.replace(',', '.'))

    setValue(e.target.value)
    updateRow({
      ...row,
      [field]: e.target.value,
    })
  }, [updateRow, field, row])

  const inputsRef = { current: { value: 0 } }
  const rowIndex = 0

  //   const handleKeyDown = useCallback(
  //     (e: KeyboardEvent<HTMLInputElement>) => {
  //       if (e.key === 'Enter') {
  //         const nextInput = inputsRef?.current[rowIndex + 1];
  //         console.log(nextInput, 'nextInput');
  //         if (nextInput) {
  //           nextInput.focus();
  //         } else {
  //           e.currentTarget.blur();
  //         }
  //       }
  //     },
  //     [inputsRef, rowIndex]
  //   );

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="p-inputgroup">
       
        <InputText
          ref={inputRef}
          disabled={!userScenery.write}
          style={{
            height: 32,
            borderColor: borderColor,
            textAlign: 'center',
            maxWidth: 80,
          }}
          value={value}
          onBlur={onBlur}
          placeholder={focusValue}
          onChange={onChange}
          //   onKeyDown={handleKeyDown}
          onFocus={onFocus}
        />
         <span className="p-inputgroup-addon">%</span>
      </div>
    </div>
  )
}

export default InputToFilter
