import { useCallback, useState } from 'react'

import Cards from './Cards'
import Table from './Table'
import Header from './Header'
import IFilters from './types/IFilters'
import { BodyContent, Container, HeadContent } from './styles'
import { colsSimple } from './Table/dynamicColumns'

const PriceBaseTable: React.FC = () => {
  const [filters, setFilters] = useState({} as IFilters)
  const [typeView, setTypeView] = useState<string>('simple')
  const [getBigData, setGetBigData] = useState<boolean>(false)
  const [refreshVerifyStatus, setRefreshVerifyStatus] = useState(false)
  const [ids, setIds] = useState<number[]>([])
  const [refreshBigNumbers, setRefreshBigNumbers] = useState<boolean>(false)
  const [columns, setColumns] = useState(colsSimple)
  const [newColumns, setNewColumns] = useState(colsSimple)
  const [dinamicFilterData, setDinamicFilterData] = useState()

  const toggleBigData = useCallback(() => {
    setGetBigData((current) => !current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <HeadContent>
        <Header
          filters={filters}
          setFilters={(setFilters)}
          typeView={typeView}
          setTypeView={setTypeView}
          refreshVerifyStatus={refreshVerifyStatus}
          columns={columns}
          newColumns={newColumns}
          setNewColumns={setNewColumns}
          dinamicFilterData={dinamicFilterData}
        />
        <Cards
          refreshBigNumbers={refreshBigNumbers}
          getBigData={getBigData}
          ids={ids}
          typeView={typeView}
          filter={filters}
        />
      </HeadContent>

      <BodyContent>
        <Table
        setDinamicFilterData={setDinamicFilterData}
          newColumns={newColumns}
          setColumns={setColumns}
          handleRefresh={() => setRefreshBigNumbers(!refreshBigNumbers)}
          handleSetIds={setIds}
          filters={filters}
          typeView={typeView}
          toggleBigData={toggleBigData}
          handleRefreshVerifyStatus={(e) => setRefreshVerifyStatus(e)}
        />
      </BodyContent>
    </Container>
  )
}

export default PriceBaseTable
