import { Flex } from '@chakra-ui/react'
import { useMemo } from 'react'

interface IProps {
  status: string
}

const Status: React.FC<IProps> = ({ status }) => {
  const color = useMemo(() => {
    if (/aguardando|editando/i.test(status)) return 'yellow'
    if (status === 'APPROVED' || status === 'Aprovado') return 'green'
    if (status === 'REJECTED' || status === 'Reprovado') return 'red'
    if (status === 'Processando') return 'blue'
    return 'gray'
  }, [status])

  const filterStatusName = (status: string) => {
    if (status === 'APPROVED') return 'Aprovado'
    if (status === 'REJECTED') return 'Reprovado'
    return status
  }

  return (
    <Flex
      color={`${color}.700`}
      bgColor={`${color}.100`}
      py="0.5rem"
      maxW="15rem"
      justify="center"
      textAlign="center"
      lineHeight="1"
      fontWeight="md"
      borderRadius="xl"
    >
      {filterStatusName(status)}
    </Flex>
  )
}

export default Status
