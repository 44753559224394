import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Column } from 'primereact/column'
import { createDynamicColumns } from './createDynamicColumns'
import { DataTable } from 'primereact/datatable'
import { IRow } from './types'
import { useParams } from 'react-router-dom'
import {
  colsDetailed,
  colsSimple,
  colsTradingCondition,
} from './dynamicColumns'
import { Button } from 'primereact/button'
import { formatDataChildren } from './actionsChildren'
import 'primeicons/primeicons.css'
import api from 'services/api'
// import { Checkbox } from 'primereact/checkbox'
import { FaCheckSquare, FaTimes, FaWindowClose } from 'react-icons/fa'
import { Checkbox } from '@chakra-ui/react'
import Money from './Money'
import Percent from './Percent'
import Volume from './Volume'
import PPVFinal from './PPVFinal'
import CompetitorListModal from './CompetitorListModal'
interface Child {
  id: number
}

interface IMainTableProps {
  rows: IRow[]
  getRows: () => void
  toggleBigData: () => void
  // inputsRef: React.MutableRefObject<any[]>
  selectedRows: any
  onSelectionChange: any
  handleHeaderCheckboxChange: any
  allChecked: any
  setRows: any
  statusScenery: boolean
  typeView: string
  page: number
  setPage: (page: number) => void
  limit: number
  setLimit: (limit: number) => void
  newColumns: any
  setColumns: any
  sort: number
  setSort: (sort: number) => void
  setOrderBy: (orderBy: string) => void
  // setTotal: (number: number) => void
  total: number
}

const MainTable: React.FC<IMainTableProps> = ({
  rows,
  getRows,
  toggleBigData,
  // inputsRef,
  selectedRows,
  onSelectionChange,
  handleHeaderCheckboxChange,
  allChecked,
  setRows,
  statusScenery,
  typeView,
  limit,
  setLimit,
  page,
  setPage,
  newColumns,
  setColumns,
  sort,
  setSort,
  setOrderBy,
  // setTotal,
  total,
}) => {
  const { id } = useParams()
  const [childrenData, setChildrenData] = useState<Record<string, Child[]>>({})
  const [data, setData] = useState(rows)
  const [order, setOrder] = useState(1)
  const [scrollHeight, setScrollHeight] = useState('55vh')
  const [openCompetitorModal, setOpenCompetitorModal] = useState(false)
  const [rowSelected, setRowSelected] = useState({})
  useEffect(() => {
    const updateScrollHeight = () => {
      const height = window.innerHeight
      if (height > 1280) {
        setScrollHeight('65vh')
      } else {
        setScrollHeight('55vh')
      }
    }
    updateScrollHeight()
  }, [])

  useEffect(() => {
    if (typeView === 'simple') {
      setColumns(colsSimple)
    } else if (typeView === 'detailed') {
      setColumns(colsDetailed)
    } else if (typeView === 'trading_condition') {
      setColumns(colsTradingCondition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeView])

  const updateRow = () => {
    console.log()
  }

  const handleNewChangeCheckBox = (rowData: any) => {
    onSelectionChange(rowData)
  }

  const [expandedRows, setExpandedRows] = useState<any>({})

  const handleToggleDetails = (rowData: any, page = 1) => {
    // Adicionado parâmetro de página com default 1
    const isExpanded = !!expandedRows[rowData.id]
    const newData: any = [...rows]

    if (isExpanded) {
      const startIndex =
        newData.findIndex((item: any) => item.id === rowData.id) + 1
      let count = 0

      while (
        newData[startIndex + count] &&
        newData[startIndex + count].parentId === rowData.id
      ) {
        count++
      }

      newData.splice(startIndex, count)
      setLimit(100)
      setData(newData)
      setRows(newData)
      setExpandedRows((prev: any) => ({ ...prev, [rowData.id]: false }))
    } else {
      const currentData: any = childrenData[rowData.id]
      if (!currentData || currentData.page < page) {
        const typeViewString = typeView === 'trading_condition' ? 'C' : 'A'
        const url = `/sceneries/${id}/items/${typeViewString}?page=${
          page == 0 ? 1 : page
        }&limit=${limit}`
        api
          .get(url, {
            params: {
              captain_code: rowData.captain_code,
              channel_code: rowData.channel_code,
              cluster_id: rowData.cluster_id,
              id: rowData.id,
              status: 'S',
            },
          })
          .then((res) => {
            const formattedChildrenData = formatDataChildren(res.data.data)
            setChildrenData((prevData) => ({
              ...prevData,
              [rowData.id]: { data: formattedChildrenData, page },
            }))
            expandRow(rowData, formattedChildrenData)
          })
          .catch((error) => console.log(error))
      } else {
        expandRow(rowData, currentData.data)
      }
    }
  }

  const paginationControls = (rowData: any) => {
    const childData: any = childrenData[rowData.id]
    return (
      <div>
        {childData && childData.page > 1 && (
          <Button
            icon="pi pi-chevron-left"
            onClick={() => handleToggleDetails(rowData, childData.page - 1)}
          />
        )}
        {childData && (
          <Button
            icon="pi pi-chevron-right"
            onClick={() => handleToggleDetails(rowData, childData.page + 1)}
          />
        )}
      </div>
    )
  }

  const expandRow = (rowData: any, children: any) => {
    const newData = [...data]
    const index = newData.findIndex((item) => item.id === rowData.id) + 1

    const childrenRows = children.map((child: any, idx: any) => ({
      ...child,
      id: `${child.id}-${idx}`,
      parentId: rowData.id,
      compareId: `${child.id}-${idx}`,
    }))
    newData.splice(index, 0, ...childrenRows)
    setData(newData)
    setRows(newData)
    setLimit(newData.length)
    setExpandedRows((prev: any) => ({ ...prev, [rowData.id]: true }))
  }

  const inputsRef = useRef<any[]>([])

  useEffect(() => {
    setData(rows)
  }, [rows])

  const handleOpenCompetitor = (rowData: any, field: string) => {
    setRowSelected({ ...rowData, field })
    setOpenCompetitorModal(true)
  }

  const dynamicColumns = createDynamicColumns(
    newColumns,
    getRows,
    updateRow,
    toggleBigData,
    inputsRef,
    typeView,
    handleOpenCompetitor
  )

  const actionBodyTemplate = (rowData: any) => {
    if (rowData.sku === rowData.captain_code) {
      const expandCollapseButton = (
        <Button
          icon={
            expandedRows[rowData.id] ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
          }
          onClick={() => handleToggleDetails(rowData)}
          className="p-button-text"
        />
      )

      const paginationButtons = paginationControls(rowData)

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {expandCollapseButton}
          {expandedRows[rowData.id] && paginationButtons}
        </div>
      )
    } else {
      return null
    }
  }

  const handlePage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setData((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
    setSort(sort === 1 ? -1 : 1)
  }

  console.log(data, 'data')
  return (
    <>
      <CompetitorListModal
        isOpen={openCompetitorModal}
        row={rowSelected}
        onClose={() => setOpenCompetitorModal(false)}
      />
      <DataTable
        dataKey="id"
        scrollable
        scrollHeight={scrollHeight}
        paginator
        rows={limit}
        lazy
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: '40rem', maxHeight: '600px' }}
        value={data}
        selectionMode={null}
        onPage={handlePage}
        first={page * limit}
        totalRecords={total}
        selection={selectedRows}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
        onSort={onSort}
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd' }}
          frozen
          header={
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              isChecked={allChecked.approve}
              size="lg"
              isDisabled={statusScenery}
              // className="approve-checkbox"
              colorScheme="green"
              style={{ backgroundColor: 'transparent' }}
              onChange={() => handleHeaderCheckboxChange('approve')}
            />
          }
          body={(rowData) => (
            <Checkbox
              sx={{
                '.chakra-checkbox__control': {
                  borderColor: 'black',
                },
              }}
              size="lg"
              colorScheme="green"
              // icon={<FaCheckSquare color="#5CFB6B" size={20} />}
              // className="approve-checkbox"
              isDisabled={statusScenery}
              isChecked={rowData.status === 'S'}
              onChange={() =>
                handleNewChangeCheckBox({
                  ...rowData,
                  status: 'S',
                })
              }
            />
          )}
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd' }}
          frozen
          header={
            <Checkbox
              isInvalid
              colorScheme="red"
              size="lg"
              icon={<FaTimes color="white" size={10} />}
              isDisabled={statusScenery}
              // className="disapprove-checkbox"
              style={{ backgroundColor: 'transparent' }}
              isChecked={allChecked.reject}
              onChange={() => handleHeaderCheckboxChange('reject')}
            />
          }
          body={(rowData) => (
            <Checkbox
              colorScheme="red"
              isInvalid
              size="lg"
              icon={<FaTimes color="white" size={10} />}
              isDisabled={statusScenery}
              // className="disapprove-checkbox"
              style={{ backgroundColor: 'transparent' }}
              isChecked={rowData.status === 'N'}
              onChange={() =>
                handleNewChangeCheckBox({
                  ...rowData,
                  status: 'N',
                })
              }
            />
          )}
        ></Column>
        <Column
          body={actionBodyTemplate}
          header="Ações"
          style={{ width: '150px' }}
          frozen
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        />
        {dynamicColumns}
      </DataTable>
    </>
  )
}

export default MainTable
