import React, { useEffect, useMemo, useState } from 'react'
import Paper from 'components/Paper'
import TableBackend from 'components/TableBackend'
import LoadingScreen from 'components/LoadingScreen'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'
import IRow from '../types/IRow'
import Input from './Input'
import api from '../../../../../services/api'
import { Flex, useToast } from '@chakra-ui/react'
import Button from 'components/Button'
import MediaPond from './MediaPond'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  loading: boolean
  clusterId: string
  binaryPos: number[]
}

interface ICellProps {
  row: IRow
  clusterId: string
  updateRow: (id: string, updatedRow: IRow, param: string) => void
}

const Table: React.FC<IProps> = ({
  rows,
  loading,
  clusterId,
  page,
  limit,
  total,
  totalPages,
  setPage,
}) => {
  const [changedRowsMercado, setChangedRowsMercado] = useState<IRow[]>([])
  const [rowsState, setRowsState] = useState<any[]>([])
  const toast = useToast()
  const [order, setOrder] = useState(1)

  useEffect(() => {
    setRowsState(rows)
  }, [rows])

  const cols = useMemo(() => {
    const nivelCols = [
      {
        Header: 'Canal',
        accessor: 'channel_name',
      },
      {
        Header: 'Nível 1',
        accessor: 'level1_name',
      },
      {
        Header: 'Nível 2',
        accessor: 'level2_name',
      },
      {
        Header: 'Nível 3',
        accessor: 'level3_name',
      },
      {
        Header: 'Nível 4',
        accessor: 'level4_name',
      },
      {
        Header: 'Nível 5',
        accessor: 'level5_name',
      },
    ]

    const baseCols = [
      {
        Header: 'Mrg. Regular',
        accessor: ({ row, clusterId, updateRow }: ICellProps) => (
          <Input
            row={row}
            param="regular_margin"
            clusterId={clusterId}
            onUpdate={updateRow}
          />
        ),
      },
      {
        Header: 'Mrg. Promoção',
        accessor: ({ row, clusterId, updateRow }: ICellProps) => (
          <Input
            row={row}
            param="promo_margin"
            clusterId={clusterId}
            onUpdate={updateRow}
          />
        ),
      },
      {
        Header: 'Média Pond',
        accessor: ({ row }: ICellProps) => <MediaPond row={row} />,
      },
      {
        Header: 'Part. % Regular',
        accessor: ({ row, clusterId, updateRow }: ICellProps) => (
          <Input
            row={row}
            param="regular_share"
            clusterId={clusterId}
            onUpdate={updateRow}
          />
        ),
      },
      {
        Header: 'Part.% Promoção',
        accessor: ({ row, clusterId, updateRow }: ICellProps) => (
          <Input
            row={row}
            param="promo_share"
            clusterId={clusterId}
            onUpdate={updateRow}
          />
        ),
      },
    ]

    return [...nivelCols, ...baseCols]
  }, [])

  const updateRow = (id: string, updatedRow: IRow, param: string) => {
    const existingRowIndex = changedRowsMercado.findIndex(
      (item) => item.id === id
    )

    let newRow = { ...updatedRow }

    if (existingRowIndex !== -1) {
      newRow = {
        ...changedRowsMercado[existingRowIndex],
        [param]: updatedRow[param],
      }
    }

    let regularValue = +newRow[param]
    let promoValue =
      param === 'regular_share' ? 100 - regularValue : +newRow['promo_share']

    if (regularValue < 0) {
      regularValue = 0
      promoValue = 100
    } else if (regularValue > 100) {
      regularValue = 100
      promoValue = 0
    } else if (promoValue < 0) {
      promoValue = 0
      regularValue = 100
    } else if (promoValue > 100) {
      promoValue = 100
      regularValue = 0
    }

    newRow[param] = regularValue
    newRow['promo_share'] = promoValue

    const updatedRows = rowsState.map((row) => (row.id === id ? newRow : row))

    setRowsState(updatedRows)

    if (existingRowIndex !== -1) {
      const newChangedRows = [...changedRowsMercado]
      newChangedRows[existingRowIndex] = newRow
      setChangedRowsMercado(newChangedRows)
    } else {
      setChangedRowsMercado([...changedRowsMercado, newRow])
    }
  }

  const handleSave = async () => {
    try {
      const url = '/market-margins'

      const newList: IRow[] = []

      changedRowsMercado.forEach((row) => {
        newList.push({
          id: row?.id,
          level1_code: row?.level1_code,
          level2_code: row?.level2_code,
          level3_code: row?.level3_code,
          level4_code: row?.level4_code,
          level5_code: row?.level5_code,
          cluster_id: Number(row?.cluster_id),
          channel_code: row?.channel_code,
          promo_margin: Number(row.promo_margin),
          promo_share: Number(row.promo_share),
          regular_margin: Number(row.regular_margin),
          regular_share: Number(row.regular_share),
        })
      })

      const response = await api.put(url, newList)

      const responseMapped = rowsState.map((item) => {
        const sameItem = response.data.data.market_margin.find(
          (value: any) => value.id === item.id
        )

        if (sameItem) {
          return {
            ...item,
            average: sameItem.average ?? item.average,
            regular_share: sameItem.regular_share ?? item.regular_share,
            promo_share: sameItem.promo_share ?? item.promo_share,
          }
        }
        return item
      })

      setRowsState(responseMapped)

      setChangedRowsMercado([]) // Supondo que o resultado retornado esteja no formato de dados esperado
      toast({
        title: 'Atualização efetuada com sucesso',
        status: 'success',
        isClosable: true,
      })
      // setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      toast({
        title: 'Erro, tente novamente.' + error,
        status: 'error',
        isClosable: true,
      })
      console.error('Erro ao salvar os dados:', error)
      // Trate o erro de acordo com a lógica da sua aplicação
    }
  }

  const marginPromoTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="promo_margin"
        clusterId={clusterId}
        onUpdate={updateRow}
      />
    )
  }

  const marginRegularTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="regular_margin"
        clusterId={clusterId}
        onUpdate={updateRow}
      />
    )
  }

  const partRegularTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="regular_share"
        clusterId={clusterId}
        onUpdate={updateRow}
      />
    )
  }

  const partPromoTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="promo_share"
        clusterId={clusterId}
        onUpdate={updateRow}
      />
    )
  }

  const mediaPondTemplate = (rowData: any) => {
    return <MediaPond row={rowData} />
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setRowsState((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  if (!clusterId) return null
  if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%' }}>
      <Flex marginBottom={5} justify="right">
        <Button onClick={handleSave}>Salvar Alterações</Button>
      </Flex>
      <DataTable
        onSort={onSort}
        scrollable
        scrollHeight="flex"
        paginator
        rows={15}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rowsState}
        // selectionMode="multiple"
        // selection={selectedRows}
        // onSelectionChange={onSelectionChange}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="cluster_name"
          header="Cluster"
          style={{ minWidth: '100px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="channel_name"
          header="Canal"
          style={{ minWidth: '100px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level1_name"
          header="Nível 1"
          style={{ minWidth: '120px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level2_name"
          header="Nível 2"
          style={{ minWidth: '80px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level3_name"
          header="Nível 3"
          style={{ minWidth: '100px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level4_name"
          header="Nível 4"
          style={{ minWidth: '100px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level5_name"
          header="Nível 5"
          style={{ minWidth: '100px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field=""
          header="Mrg/Mkp Regular"
          body={marginRegularTemplate}
          style={{ minWidth: '50px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field=""
          header="Mrg/Mkp Promoção"
          body={marginPromoTemplate}
          style={{ minWidth: '50px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="range_min"
          header="Média Pond."
          body={mediaPondTemplate}
          style={{ minWidth: '50px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field=""
          header="Part. % Regular"
          body={partRegularTemplate}
          style={{ minWidth: '50px' }}
          frozen
        ></Column>
        <Column
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field=""
          header="Part. % Promoção"
          body={partPromoTemplate}
          style={{ minWidth: '50px' }}
          frozen
        ></Column>
      </DataTable>
      {/* <TableBackend
        rows={rowsState}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        setPage={setPage}
        totalPages={totalPages}
        cellProps={{ clusterId, updateRow }}
      /> */}
    </Paper>
  )
}

export default Table
