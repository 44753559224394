import { useCallback, useContext, useEffect, useState } from 'react'
import Table from './Table'
import { Paper } from '@material-ui/core'
import IFilters from '../MargemIndustria/types/IFilters'
import { getTradingConditionListService } from 'services/CondicoesComerciaisTable/get-trading-condition-list.service'
import { UserContext } from 'state/user-context'
import { getClusterConcorrenteListService } from 'services/ClusterConcorrenteTable/cluster-concorrente-service'

const limit = 15
const binaryPos = [1, 2, 4, 8, 16]

const ClusterConcorrente = () => {
  const { user } = useContext(UserContext)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<any>({})

  useEffect(() => {
    setPage(1)
    setLoading(true)
  }, [filters])

  useEffect(() => {
    if (
      user?.newClusters &&
      user?.newClusters?.length > 0 &&
      !user?.clusters?.includes(0)
    ) {
      setFilters({
        ...filters,
        cluster_id: user?.newClusters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const getRows = useCallback(async () => {
    const request: any = {}

    const newFilters: any = {
      cluster_id: Number(filters.clusterSelected?.value),
      sku: filters.skuSelected?.value,
      description_priority: filters.prioritySelected?.value,
      competitor_description: filters.competitorSelected?.value,
      channel_code: filters.channelsSelected?.value,
      uf: filters.ufSelected?.value,
    }

    // if (!Array.isArray(filters)) {
    //   Object.keys(filters).forEach((key) => {
    //     if (Array.isArray(filters[key])) {
    //       const filteredValues = filters[key].filter(
    //         (v: IOption | null) => v !== null
    //       )

    //       if (filteredValues.length > 0) {
    //         if (key === 'cluster_id') {
    //           request[key] = filteredValues.map((v: IOption) =>
    //             Number(v?.value)
    //           )
    //         } else {
    //           request[key] = filteredValues.map((v: IOption) => v?.value)
    //         }
    //       }
    //     } else {
    //       const { value }: any = filters[key] || {}
    //       if (value !== null && value !== undefined) {
    //         request[key] = [value]
    //       }
    //     }
    //   })
    // }

    const newRequest = {...newFilters, cluster_id: request?.cluster_id?.length < 1 ? user?.clusters : request.cluster_id}

    try {
      const result = await getClusterConcorrenteListService(
        newRequest,
        page,
        limit
      )
      setRows(result.result)
      setTotal(result.total)
      setTotalPages(result.data.totalPages)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page])

  useEffect(() => {
    if (loading) getRows()
  }, [loading, getRows])

  return (
    <Paper>
      <Table
        rows={rows}
        loading={loading}
        clusterId={'0'}
        binaryPos={binaryPos}
        page={page}
        limit={limit}
        total={total}
        setPage={(newVal) => {
          if (newVal === page) return
          setPage(newVal)
          setLoading(true)
        }}
        totalPages={totalPages}
        filters={filters}
        setFilters={setFilters}
        setLoading={setLoading}
      />
    </Paper>
  )
}

export default ClusterConcorrente
